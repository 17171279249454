<template>
  <div class="wrapper">
    <admin-title :title="$route.params.id ? '编辑角色' : '新增角色'"></admin-title>
    <a-divider />
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="角色名" prop="role_name">
        <el-input style="width:400px" v-model="formData.role_name" placeholder="角色名"></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="remark">
        <el-input
          type="textarea"
          rows="3"
          style="width:400px"
          maxlength="200"
          show-word-limit
          v-model="formData.remark"
          placeholder="角色描述"
        ></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="" @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addRole, editRole, getRoleDetail } from './api'
export default {
  name: 'Detail',

  data() {
    return {
      formData: {
          role_name: '',
        remark: ''
      },
      rules: {
        role_name: [
          { required: true, message: '该项不能为空', trigger: 'blur' }
        ],
        remark: [
          { max: 200, message: '角色描述不能超过200个字符', trigger: 'blur' }
        ]
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getRoleDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.id) {
            // 编辑
            await editRole(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增
            await addRole(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    async getRoleDetail() {
      const res = await getRoleDetail({ id: this.$route.params.id })
      this.formData = res
    },
    cancel() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 70%;
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
